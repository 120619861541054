{
  "name": "classroom-studio",
  "version": "0.0.23",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "prebuild": "npm --no-git-tag-version version patch",
    "serve:ssr:classroom-studio": "node dist/classroom-studio/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.3.9",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.3.9",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/platform-server": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/ssr": "^17.0.6",
    "@ckeditor/ckeditor5-angular": "^7.0.1",
    "@ckeditor/ckeditor5-build-classic": "^41.1.0",
    "@ckeditor/ckeditor5-core": "^41.1.0",
    "@ckeditor/ckeditor5-engine": "^41.1.0",
    "@ckeditor/ckeditor5-utils": "^41.1.0",
    "@ckeditor/ckeditor5-watchdog": "^41.1.0",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/resource": "^6.1.15",
    "@fullcalendar/resource-timegrid": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@popperjs/core": "^2.11.8",
    "bootstrap": "^5.3.3",
    "canvas-confetti": "^1.9.3",
    "express": "^4.18.2",
    "localstorage-polyfill": "^1.0.1",
    "luxon": "^3.4.4",
    "ngx-cookie": "^6.0.1",
    "ngx-cookie-service": "^17.0.1",
    "ngx-mat-timepicker": "^17.1.0",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-quill": "^25.0.3",
    "ngx-ui-tour-md-menu": "^12.0.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "typescript-cookie": "^1.0.6",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/localize": "^17.0.0",
    "@types/canvas-confetti": "^1.6.4",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.2.2"
  }
}
