import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Toast } from '../models/toast.interface';
import { ToastType } from '../models/toast.type';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  subject: BehaviorSubject<Toast | undefined>;
  toast$: Observable<Toast | undefined>;

  constructor() {
    this.subject = new BehaviorSubject<Toast | undefined>(undefined);
    this.toast$ = this.subject
      .asObservable()
      .pipe(filter((toast) => toast !== undefined));
  }

  show(
    type: ToastType,
    title: string | null,
    body: string | null,
    delay: number | null
  ) {
    this.subject.next({ type, title, body, delay });
    this.subject.next(undefined);
  }
}
