import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  public set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public get(key: string) {
    return localStorage.getItem(key);
  }
  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }

  public getFrontEndNames() {
    return {
      lesson: localStorage.getItem('lesson-name') || 'Lesson',
      unit: localStorage.getItem('unit-name') || 'Unit',
      topic: localStorage.getItem('topic-name') || 'Topic',
      activity: localStorage.getItem('activity-name') || 'Activity',
    };
  }
  public getFrontEndAccountValues() {
    return {
      lesson: localStorage.getItem('show_tutorial') || true,
    };
  }
}
